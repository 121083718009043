import { createApp, defineAsyncComponent } from 'vue';
import router from './router';

import '@index5/face-detector/style.css';
import 'vue-final-modal/style.css';
import './index.css';

import '@/plugins/fwIcons';

import App from './App.vue';

import { VueCookieNext } from 'vue-cookie-next';

import { pinia } from '@/pinia';

import { vuetify } from '@/plugins';
import { initValidator } from '@/utils/validator';

// @ts-ignore
import i18n from '@/i18n';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import ColorIcon from '@/components/ColorIcon.vue';
import DefaultIcon from '@/components/Icon.vue';
import Input from '@/components/Input.vue';
import Button from '@/components/Button.vue';
import DSelect from '@/components/DSelect.vue';
import DPopperInfo from '@/components/DPopperInfo.vue';
import DNotification from '@/components/DNotification.vue';
import DatePicker from '@/components/DatePicker.vue';
import DTooltip from '@/components/DTooltip.vue';
import Popper from 'vue3-popper';
import { createVfm } from 'vue-final-modal';
import { initSentry } from './plugins/sentry';

const DModal = defineAsyncComponent(
    () => import('@/components/modal/DModal.vue'),
);

const app = createApp(App);

initSentry(app, router);

dayjs.extend(utc);
initValidator();

app.use(pinia)
    .use(router)
    .use(i18n)
    .use(createVfm())
    .use(vuetify)
    .use(VueCookieNext)
    .component('FaIcon', FontAwesomeIcon)
    .component('c-icon', ColorIcon)
    .component('d-icon', DefaultIcon)
    .component('d-input', Input)
    .component('d-button', Button)
    .component('d-select', DSelect)
    .component('d-modal', DModal)
    .component('d-popper-info', DPopperInfo)
    .component('d-notification', DNotification)
    .component('date-picker', DatePicker)
    .component('d-tooltip', DTooltip)
    .component('popper', Popper)
    .mount('#app');

console.log(import.meta.env);
